#introsection::before,
#introsection::after {
    float: center;
    color: rgb(0, 134, 244);
}

#introsection {
    text-align: left;
    padding: 40px;
    font-family: 'monospace';
    overflow-y: auto;
}

#introsection h1 {
    line-height: 33px;
    margin: .5em 0;
    margin-bottom: 1.5em;
}

#introsection h2 {
    font-size: 14px;
    font-weight: 700;
    margin: 1em 0;
}

#introsection p {
    font-size: 14px;
    font-weight: 700;
    margin: 1em 0;
    margin-bottom: 2rem;
}

#introsection .separator {
    font-family: var(--text-font);
    background: url('../../assets/images/dash.png') repeat-x;
    filter: invert();
    height: 10px;
    margin-bottom: 10px;
}

#introsection ul {
    list-style-type: square;
}

#introsection pre {
    margin: 0;
    display: inline;
}

#introsection h2 span, #introsection p span {
    color: var(--green-color);
}

.chatContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    width: 100%;
    max-width: 1400px;
    padding: 20px 0;
    box-sizing: border-box;
}

.emptyChatTile {
    display: flex;
    flex: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    width: calc(33.33% - 20px);
    transition: transform 0.2s;
    box-sizing: border-box;
    user-select: none;
    min-width: 300px;
    cursor: pointer;
}

.emptyChatTile:hover {
    transform: scale(1.05);
}

.emptyChatTitle {
    padding: 0 12px;
    font-size: 14px;
}

.emptyChatDescription {
    font-size: 14px;
    color: #666;
    word-break: break-word;
}

@media screen and (max-width: 1024px) {
    #introsection {
        padding: 20px 20px 40px 20px;
        font-size: 14px;
    }

    #introsection h1,
    pre {
        font-size: 16px;
        white-space: pre-wrap;
    }

    #introsection h2 {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .emptyChatTile {
        width: calc(50% - 20px); /* Two tiles in smaller screens */
    }
}

@media (max-width: 480px) {
    .emptyChatTile {
        width: 100%; /* One tile in mobile screens */
    }
}