:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
  --green-color: #24a429;
  --bg-color: #1e1e1e;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
  display: flex;
}

.menu-bg {
  background-color: #1E1E1E;
  height: 36px;
}

.menu-bg img {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

nav {
  display: none;
  background-color: #4c4f5f;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}
.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}

.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}
.navPrompt button {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
  background: none;
  border: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
  text-align: left;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  position: relative;
  /* top: -45px; */
  white-space: break-spaces;
}

#botMessage {
  max-width: 700px;
  white-space: break-spaces;
  overflow-wrap: anywhere;
}

pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #202022;
}
.sideMenuButton {
  display: flex;
  align-items: center;
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}
.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  /* background-color: #353540; */
  background: #1e1e1e;
  position: relative;
  /* line-height: 24px; */
  color: #d1d5db;
  font-size: 16px;
}

.chatLogWrapper {
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  /* max-width: 800px;
  margin: auto; */
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  /* background-color: #444654; */
  position: relative;
}
.botMessageWrapper {
  /* max-width: 800px;
  margin: auto; */
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}
.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  margin: 40px 20px 12px 20px;
  max-height: 200px;
  height: 55px;
  border-top: 1px solid #41414e;
  display: flex;
  align-items: center;
}

.inputLockArea {
  flex: 1;
}

.inputPrompttTextarea {
  padding: 10px;
  flex: 1;
  resize: none;
  background-color: #1e1e1e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
}
form button {
  border: none;
  width: 35px;
  height: 45px;
  background-color: #1e1e1e;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 680px) and (max-width: 1024px) {
  .chatBox {
    height: calc(100% - 35px);
  }

  .menu-bg img {
    width: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px;
  }
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
    height: calc(100vh - 55px);
    padding-top: 10px;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }

  #botMessage {
    /* full screen width - 2x padding - flex column gap - avatar width - scrollbar width */
    max-width: calc(100vw - 24px - 25px - 244px - 8px);
  }  
}

@media screen and (max-width: 767px) {
  #botMessage {
    /* full screen width - 2x padding - flex column gap - avatar width - scrollbar width */
    max-width: calc(100vw - 24px - 25px - 30px - 8px);
  }
}

.signupSeparator {
  color: white !important;
}


.signupFormContainer {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-family: var(--text-font);
}
.signupFormContainer h1 {
  color: #2d333a;
}
.signupFormContainer form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signupFormContainer input {
  width: 250px;
  padding: 12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.signupFormContainer input:focus {
  outline: none;
  border-color: #505ef6;
}

.signupFormContainer button {
  width: 275px;
  border: none;
  border-radius: 5px;
  padding: 12px;
  margin-top: 5px;
  background-color: #10a37f;
  color: #fff;
  cursor: pointer;
  position: static;
}

.signupFormContainer button:hover {
  background: #16c399;
}

.signupFormContainer span {
  font-size: 15px;
  color: red;
  margin-top: 10px;
}

#signupPassword {
  display: flex;
  align-items: center;
  position: relative;
}

#signupPassword svg {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.signupSeparator::before,
.signupSeparator::after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  padding: 0 15px;
  color: #a9acb0;
}

.signupSeparator {
  font-size: 16px;
  color: #2d333a;
  padding: 20px 0;
}

#signupWithGoggle {
  background: #fff !important;
  color: #2d333a !important;
  border: 1px solid #202123 !important;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
}

#signupWithGoggle:hover {
  background-color: rgb(236, 236, 236) !important;
}

.empty-chat-image {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
}

.botMessage h1,
.botMessage h2,
.botMessage h3,
.botMessage h4,
.botMessage h5,
.botMessage h6,
.botMessage p,
.botMessage ul,
.botMessage ol,
.botMessage li,
.botMessage li ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.chatPrompt, .botMessage {
  max-width: 700px;
  font-family: monospace;
  word-break: break-word;
}

.chatPrompt > span {
  color: var(--green-color);
}

.logo-sub {
  font-weight: 300;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.imageGrid img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.hgpt-avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  clip-path: circle();
}

.user-avatar {
  width: 120%;
  height: 100%;
  object-fit:cover ;
  border-radius: 50%;
  clip-path: circle(); 
  background-blend-mode: multiply;
  border: 2px solid #1e1e1e;
}

#react-modal-image-img {
  object-fit: contain;
}

.screenshot {
  max-height: 300px !important;
  object-fit: cover;
  cursor: pointer;
}

/* Hide the element by default */
.hide-on-mobile-screens {
  display: none;
}

/* Show as block on medium screens (768px) and larger */
@media (min-width: 768px) {
  .hide-on-mobile-screens {
      display: block;
  }
}


code.code {  
  background: #2a2c2c;
  white-space: pre-wrap;
  padding: 12px;
  color: #e0e3e4;
  margin: 10px 0;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

code.code:hover {  
  background: #1E1E1E;
  color: #e0e3e4;
}

a[href^="mailto:"] {
  color: #1a73e8;
  text-decoration: underline;
  font-weight: bold;
  text-decoration: none;
}

.noscroll {
  overflow: hidden;
}

#botMessage a {
  color: #505ef6;
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292929;
  padding: 0.5rem 1rem 0.7rem;
  margin-top: 0.5em;
  margin-bottom: -0.7em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

.code-block-actions {
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .code-block-header {
    padding: 0.5rem;
    padding-bottom: 0.7rem;
  }
}