.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.navPrompt {
    display: flex;
    align-items: center;
    padding: 10px 4px;
    column-gap: 10px;
    text-align: left;
}

.btn {
    display: flex;
    align-items: center;
    column-gap: 20px;
    text-decoration: none;
    background: none;
    border: none;
}

.text {
    font-size: 14px;
    line-height: 20px;
    color: #ececf1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
    margin: 0;
    text-align: left;
}
  
.text span {
    animation: fadeInChar 1s ease-in forwards;
    opacity: 0;
}

.wrapper:has(.navPrompt:hover) {
    cursor: pointer;
    background-color: hsla(240, 9%, 59%, 0.1);
}

.closeBtn {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.closeBtn:hover {
    transform: scale(1.2);
}  

@keyframes fadeInChar {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
